<template>
  <div
    v-if="isInitialised"
    class="RequisitionPDFBaseInfo">
    <div class="Top">
      <!-- Company info -->
      <div class="Block">
        <table>
          <tr>
            <th colspan="2">{{ mixWBBase('REQUESTER_AND_INVOICE_SENT_TO') }}</th>
          </tr>
          <tr>
            <td>{{ mixWBBase('COMPANY_NAME') }}</td>
            <td>{{ receiptAccount.name }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('ADDRESS') }}</td>
            <td>{{ receiptAccount.address }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('POSTAL_CODE_AND_CITY') }}</td>
            <td>{{ receiptAccount.postalCode }} {{ receiptAccount.city }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('COUNTRY') }}</td>
            <td>{{ countryName }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('CONTACT_PERSON') }}</td>
            <td>{{ receiptAccount.contactPerson.name }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('PHONE_NUMBER') }}</td>
            <td>{{ receiptAccount.contactPerson.phoneNumber }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('EMAIL') }}</td>
            <td>{{ receiptAccount.contactPerson.email }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('OFFER_NUMBER') }}</td>
            <td>{{ offerNumberText }}</td>
          </tr>
          <tr v-if="projectRequisitionData.eanNumber">
            <td>{{ mixWBBase('EAN_NUMBER') }}</td>
            <td>{{ projectRequisitionData.eanNumber }}</td>
          </tr>
          <tr>
            <th colspan="2">{{ mixWBBase('DELIVERY') }}</th>
          </tr>
          <tr>
            <td
              v-html="deliveryTimeText"
              colspan="2" />
          </tr>
        </table>
      </div>

      <!-- Screener info -->
      <div class="Block">
        <table>
          <tr>
            <th colspan="2">{{ mixWBBase('SAMPLER') }} / {{ mixWBBase('CONTACT_PERSON') }}</th>
          </tr>
          <tr>
            <td>{{ mixWBBase('NAME') }}</td>
            <td>{{ screener ? screener.fullName : '-' }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('PHONE_NUMBER') }}</td>
            <td>{{ screener ? mixFormatPhoneNumber(screener.phoneNumber) : '-' }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('EMAIL') }}</td>
            <td>{{ screener ? screener.email : '' }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('WITHDRAWAL_DATE') }}</td>
            <td>{{ screeningDate }}</td>
          </tr>
          <tr v-if="personalSamplingID">
            <td>{{ mixWBBase('CASE_NUMBER') }}</td>
            <td>{{ personalSamplingID }}</td>
          </tr>
          <tr v-else>
            <td>{{ mixWBBase('CASE_NUMBER') }}</td>
            <td>{{ caseNumber }}</td>
          </tr>
          <tr>
            <td>{{ mixWBBase('CASE_NAME') }}</td>
            <td>{{ currentScreeningData.getAddressLine() }}</td>
          </tr>
          <tr>
            <th colspan="2">{{ mixWBBase('TOTAL_NUMBER_OF_SAMPLES') }}</th>
          </tr>
          <tr>
            <td colspan="2">
              {{ samplesTotal === 1 ? mixWBBase('1_PIECE') : mixWBBase('X_PCS', [samplesTotal]) }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <!-- Extra info -->
    <!-- - Emails for analyses report -->
    <div class="Options">
      <div class="Option">
        <span class="Title">{{ mixWBBase('W: Analyserapport sendes til') }}</span>
        <span>{{ analysisReportEmails }}</span>
      </div>
    </div>

    <!-- - Chlorinated paraffins note -->
    <div class="Options">
      <div class="Option">
        <span class="Title">{{ mixWBBase('CHLORINATED_PARAFFINS') }}</span>
        <div class="Item">
          <Checkbox
            name="kp-analyses"
            :text="mixWBBase('IF_CHLORINATED_PARAFFINS_HAVE_BEEN_DETECTED_TEXT')"
            isSelected />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/FormElements/Checkbox.vue'
import { mapGetters } from 'vuex'
import { union } from 'lodash-es'
import milvaLabDetails from '@/globals/json/milva-lab-details.json'

export default {
  name: 'RequisitionPDFBaseInfo',
  props: {
    samplesTotal: {
      type: Number,
      required: true,
    },
    deliveryTimeText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isInitialised: false,
    }
  },
  computed: {
    ...mapGetters([
      'allUsers',
      'caseNumber',
      'currentAccount',
      'currentScreeningData',
      'personalSamplingID',
      'holdensenAccount',
    ]),
    milvaSelected() {
      return this.projectRequisitionData.laboratoryID === 'milvaEuroFins'
    },
    receiptAccount() {
      if (!this.milvaSelected) {
        return this.currentAccount
      }

      return milvaLabDetails
    },
    projectRequisitionData() {
      return this.currentScreeningData.requisition
    },
    offerNumberText() {
      if (this.milvaSelected) {
        return milvaLabDetails.euroFinsNumber
      }

      if (this.projectRequisitionData.customOfferNumber) {
        return this.projectRequisitionData.customOfferNumber
      }

      if (this.projectRequisitionData.laboratoryID === 'euroFins') {
        return this.currentAccount.euroFinsNumber
      }

      if (this.projectRequisitionData.laboratoryID === 'hojvang') {
        return this.currentAccount.hojvangNumber
      }

      return ''
    },
    countryName() {
      return this.milvaSelected
        ? this.mixWBBase('DENMARK')
        : this.receiptAccount.getCountryName?.() || ''
    },
    screener() {
      return this.allUsers.find((x) => x.id === this.currentScreeningData.userID)
    },
    screeningDate() {
      if (!this.currentScreeningData.screeningEndTime) {
        return '-'
      }

      return this.mixGetDate(this.currentScreeningData.screeningEndTime, 'LL')
    },
    extraEmailsArray() {
      const test = this.projectRequisitionData.extraEmails.split(',')

      return test.reduce((prev, email, index) => {
        if (index > 2) {
          return prev
        }

        const trimmed = email.trim()

        if (!trimmed) {
          return prev
        }

        prev.push(email.trim())
        return prev
      }, [])
    },
    analysisReportEmails() {
      let emails = []

      // Add mapper
      if (this.screener?.email) {
        emails.push(this.screener.email)
      }

      // Add account analyses report email
      if (this.currentAccount?.analysisReportEmail) {
        emails = union(emails, [this.currentAccount.analysisReportEmail])
      }

      // Add extra emails
      emails = union(emails, this.extraEmailsArray)

      return emails.join(', ')
    },
  },
  components: { Checkbox },
  created() {
    const waitForLoad = setInterval(() => {
      if (
        !this.currentScreeningData
        || !this.caseNumber
        || !this.allUsers.length
        || !this.currentAccount
      ) {
        return
      }

      clearInterval(waitForLoad)

      this.isInitialised = true
    }, 100)
  },
}
</script>

<style lang="stylus" scoped>
  .RequisitionPDFBaseInfo
    display block
    margin 0px -10px 20px

  .Top
    display flex

  .Block
    flex-grow 2
    flex-basis 0
    margin 0 10px 20px

  table
    width 100%
    border-collapse collapse
    th
      text-align left
      background-color $color_grey_lighter
      text-transform uppercase
    th, td
      padding 5px
    td
      &:first-child
        white-space nowrap

  table, tr, th, td
    border 1px solid $color_grey_light
    font-size 0.875rem

  .Options
    margin 0 10px 20px
    padding 10px
    background-color $color_yellow_light
    .Option
      .Title
        font-size 0.875rem
        font-weight bold
        text-transform uppercase
      .Item
        margin-top 5px
      >>> .Checkbox
        margin-bottom 0
</style>
