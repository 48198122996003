<template>
  <div class="RequisitionPDFFooter">
    <span class="Pages">{{ mixWB('PAGE_X_OF_Y', [currentPage, totalPages]) }}</span>
  </div>
</template>

<script>
export default {
  name: 'RequisitionPDFFooter',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="stylus" scoped>
  .RequisitionPDFFooter
    display block

  .Pages
    position absolute
    bottom 0px
    right 0px
    color $color_grey_light
    font-size 0.875rem
</style>
