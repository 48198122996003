import { render, staticRenderFns } from "./RequisitionPDFBaseInfo.vue?vue&type=template&id=6133ded8&scoped=true&"
import script from "./RequisitionPDFBaseInfo.vue?vue&type=script&lang=js&"
export * from "./RequisitionPDFBaseInfo.vue?vue&type=script&lang=js&"
import style0 from "./RequisitionPDFBaseInfo.vue?vue&type=style&index=0&id=6133ded8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6133ded8",
  null
  
)

export default component.exports