<template>
  <div class="RequisitionApprove">
    <RequisitionPickUpNotice />

    <div
      v-if="selectedLanguage.locale !== 'da-DK'"
      class="RequisitionLanguageNotice">
      <span>{{ mixWB('REQUISITION_LANG_NOTICE') }}</span>
    </div>

    <!-- PDF pages -->
    <div class="PageList">
      <!-- First page -->
      <div class="Page">
        <div class="InnerPage">
          <RequisitionPDFHeader :laboratory="requisitionOptions.laboratory" />
          <RequisitionPDFBaseInfo
            :samplesTotal="mutatedSamples.total"
            :deliveryTimeText="deliveryTimeText" />
          <RequisitionPDFSamplesTable
            :samples="mutatedSamples.firstPage"
            :totals="mutatedSamples.totals" />
          <RequisitionPDFFooter
            :currentPage="1"
            :totalPages="1 + mutatedSamples.extraPages.length" />
        </div>
      </div>

      <!-- Extra pages -->
      <div
        v-for="(sampleList, index) in mutatedSamples.extraPages"
        :key="index"
        class="Page">
        <div class="InnerPage">
          <RequisitionPDFSamplesTable
            :samples="sampleList"
            :totals="mutatedSamples.totals" />
          <RequisitionPDFFooter
            :currentPage="index + 2"
            :totalPages="1 + mutatedSamples.extraPages.length" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequisitionPDFHeader from '@/components/RequisitionPDF/RequisitionPDFHeader.vue'
import RequisitionPDFBaseInfo from '@/components/RequisitionPDF/RequisitionPDFBaseInfo.vue'
import RequisitionPDFSamplesTable from '@/components/RequisitionPDF/RequisitionPDFSamplesTable.vue'
import RequisitionPDFFooter from '@/components/RequisitionPDF/RequisitionPDFFooter.vue'
import { sortBy } from 'lodash-es'
import { mapGetters } from 'vuex'
import RequisitionPickUpNotice from '@/components/RequisitionFlow/RequisitionPickUpNotice.vue'
import store from '@/store'
import { mixBeforeUnload, mixWB } from '@/globals/javascript/_util/mixins'

const warning = mixWB('REQUISITION_IS_SENDING_LEAVE_WARNING')

export default {
  name: 'RequisitionApprove',
  mixins: [mixBeforeUnload({
    showPrompt: 'requisitionIsSending',
    warningText: warning,
  })],
  data() {
    return {
      maxSamplesFirstPage: 8,
      maxSamplesRestOfPages: 30,
    }
  },
  computed: {
    ...mapGetters([
      'selectedLanguage',
      'requisitionUserSelections',
      'analysisDeliveryTimeOptionsAsArray',
      'currentScreeningData',
      'requisitionOptions',
      'requisitionIsSending',
    ]),
    projectRequisitionData() {
      return this.currentScreeningData.requisition
    },
    deliveryTimeText() {
      const { deliveryTime } = this.requisitionOptions

      return deliveryTime.days === 1
        ? `<b>${ deliveryTime.text }</b>`
        : deliveryTime.text
    },
    mutatedSamples() {
      let tempSamples = []
      const data = {
        firstPage: [],
        extraPages: [],
        totals: {},
        total: 0,
      }

      this.requisitionUserSelections.forEach((sample) => {
        const item = {
          title: sample.title,
          sampleNumber: sample.sampleNumber,
          sampleIDs: sample.analysesIDs,
        }

        // Update totals
        item.sampleIDs.forEach((id) => {
          if (typeof data.totals[id] === 'undefined') {
            data.totals[id] = 1
          }
          else {
            data.totals[id] += 1
          }
        })

        tempSamples.push(item)
      })

      tempSamples = sortBy(tempSamples, ['sampleNumber'])

      data.total = tempSamples.length

      // Get samples to show on first page
      data.firstPage = tempSamples.splice(0, this.maxSamplesFirstPage)

      if (!tempSamples.length) {
        return data
      }

      // Get samples to show on extra pages
      while (tempSamples.length) {
        data.extraPages.push(tempSamples.splice(0, this.maxSamplesRestOfPages))
      }

      return data
    },
  },
  components: {
    RequisitionPDFHeader,
    RequisitionPDFBaseInfo,
    RequisitionPDFSamplesTable,
    RequisitionPDFFooter,
    RequisitionPickUpNotice,
  },
  beforeRouteEnter(to, from, next) {
    // If requisition preview isn't ready
    if (!store.getters.isRequisitionPreviewAvailable) {
      next({ name: 'RequisitionCreation', params: to.params, meta: to.meta })
      return
    }

    next()
  },
}
</script>

<style lang="stylus" scoped>
  .RequisitionApprove
    > *
      max-width 794px
      margin-left auto
      margin-right auto

  .PageOptions
    width 794px
    margin 0px auto 10px
    display flex
    justify-content space-between
    align-items flex-start
    .ErrorTextWrap
      padding 0 10px
      >>> .ErrorText
        margin 0
    .SendButton
      width auto

  .PickupCallNotice
    max-width 794px
    width 100%
    margin 0px auto 20px
    padding 10px
    background-color white
    font-weight bold
    box-shadow $box_shadow_1

  .PageList
    overflow auto
    .Page
      margin 20px auto
      box-shadow $box_shadow_1

  .Page
    page()

  .InnerPage
    innerPage()

  .RequisitionLanguageNotice
    margin-top 20px
    padding 10px
    background-color #fff
    box-shadow $box_shadow_1
    font-weight bold
</style>

<style lang="stylus">
  .PageRequisition
    background-color $color_grey_lightest
    @media print
      .Requisition
        margin 0
        padding 0
        > *:not(.PageList)
          display none

    .PickupCallNotice
      a
        font-weight bold
        text-decoration underline
</style>
