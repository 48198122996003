<template>
  <div class="RequisitionPDFHeader">
    <span class="Date">{{ currentDate }}</span>

    <div class="LogoWrap">
      <h2
        v-if="useAccoutName"
        class="AccountName">{{ currentAccount.name }}</h2>
      <MultiImage
        v-else
        :image="accountImage"
        :transformation="'requisition_account_logo'"
        :keepSquare="false"
        @on-error="setFallbackAsActive" />
    </div>

    <div class="TitleWrap">
      <span class="Title">{{ mixWBBase('ANALYSIS_REQUISITION_AND_MATERIAL_SAMPLES') }}</span>
      <span class="SubTitle">
        {{ mixWBBase('ATTENTION_SHORT') }} {{ mixWBBase(attName) }}
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment-with-locales-es6'
import { mapGetters } from 'vuex'
import MultiImage from '../Images/MultiImage.vue'

export default {
  name: 'RequisitionPDFHeader',
  props: {
    laboratory: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      useAccoutName: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentAccount',
      'activeLaboratoriesAsArray',
      'selectedLanguage',
    ]),
    currentDate() {
      return moment().locale('da-DK').format('ll')
    },
    accountImage() {
      return { base: { path: this.currentAccount.images.mainLogo } }
    },
    attName() {
      if (this.laboratory.id === 'milvaEuroFins') {
        const euroFins = this.activeLaboratoriesAsArray.find((x) => x.id === 'euroFins')

        return euroFins?.translation ?? ''
      }

      return this.laboratory.translation
    },
  },
  methods: {
    setFallbackAsActive() {
      this.useAccoutName = true
    },
  },
  components: {
    MultiImage,
  },
}
</script>

<style lang="stylus" scoped>
  .RequisitionPDFHeader
    position relative
    display block
    margin-bottom 20px
  .Date
    position absolute
    top -5px
    right 0
    color $color_grey_light
    font-size 0.875rem

  .AccountName
    font-weight bold
    text-align center

  >>> .LogoWrap
    padding 5px 0px 20px
    img
      display block
      margin 0px auto
      max-width 160px
      max-height 60px
      width auto

  .TitleWrap
    span
      display block
      text-align center
    .Title
      font-size 1.5rem
      text-transform uppercase
      padding-bottom 5px
    .SubTitle
      color $color_grey
</style>
