<template>
  <div class="RequisitionPDFSamplesTable">

    <!-- Header part -->
    <table class="HeaderPart">
      <tr class="TotalRow">
        <td/>
        <td
          v-for="sample in sampleTypesAsArray"
          :key="sample.id"
          :class="colorClasses[sample.id]">
          {{ totals[sample.id] || 0 }}
        </td>
      </tr>
      <tr class="SampleTitleRow">
        <td/>
        <td
          v-for="sampleTitleData in samplesTitleData"
          :key="sampleTitleData.id"
          :class="colorClasses[sampleTitleData.id]">
          <span>{{ mixWBBase(sampleTitleData.title) }}</span>
          <span>{{ sampleTitleData.code }}</span>
        </td>
      </tr>
    </table>

    <!-- Body part -->
    <div class="TableBodyWrap">
      <!-- Sample titles -->
      <table class="BodyTitles">
        <tr
          v-for="item in samples"
          :key="item.sampleNumber">
          <td>{{ item.title.base }}</td>
        </tr>
      </table>

      <!-- Sample tests -->
      <table class="BodyTests">
        <tr
          v-for="item in samples"
          :key="item.sampleNumber">
          <td
            v-for="sample in sampleTypesAsArray"
            :key="sample.id"
            :class="colorClasses[sample.id]">
            {{ item.sampleIDs.includes(sample.id) ? 'X' : '' }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RequisitionPDFSamplesTable',
  props: {
    samples: {
      type: Array,
      required: true,
    },
    totals: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      colorClasses: {
        'ST-1': 'Yellow-1',
        'ST-2': 'Salmon-1',
        'ST-3': 'Green-1',
        'ST-4': 'Purple-1',
        'ST-5': '',
        'ST-6': 'Blue-1',
        'ST-7': 'Yellow-2',
        'ST-8': 'Salmon-2',
      },
    }
  },
  computed: {
    ...mapGetters([
      'sampleTypesAsArray',
      'activeLaboratoriesAsArray',
      'currentScreeningData',
    ]),
    selectedLaboratory() {
      return this.activeLaboratoriesAsArray.find(
        (x) => x.id === this.currentScreeningData.requisition.laboratoryID,
      )
    },
    samplesTitleData() {
      return this.sampleTypesAsArray.reduce((prev, sampleType) => {
        const data = {
          id: sampleType.id,
          title: '',
          code: '',
        }

        prev.push(data)

        if (!this.selectedLaboratory) {
          return prev
        }

        const labRequisitionItem = this.selectedLaboratory.requisition.find(
          (x) => x.sampleTypeID === sampleType.id,
        )

        data.title = labRequisitionItem.text || ''
        data.code = labRequisitionItem.code || ''
        return prev
      }, [])
    },
  },
}
</script>

<style lang="stylus" scoped>
  .RequisitionPDFSamplesTable
    display block

  .HeaderPart
    width 100%
    border-bottom none
    tr
      &:first-child
        background-color rgba($color_grey_light, 0.3)
      &:last-child
        border-bottom none
    td
      width 36px
      border-bottom none
      &:first-child
        width auto
      &.Yellow-1
        background-color rgba($color_yellow_lightest, 0.65)
      &.Yellow-2
        background-color rgba($color_yellow_lightest, 1)
      &.Salmon-1
        background-color rgba($color_salmon_lightest, 0.65)
      &.Salmon-2
        background-color rgba($color_salmon_lightest, 1)
      &.Purple-1
        background-color rgba($color_purple_lightest, 0.65)
      &.Purple-2
        background-color rgba($color_purple_lightest, 1)
      &.Green-1
        background-color rgba($color_primary_lightest, 0.65)
      &.Green-2
        background-color rgba($color_primary_lightest, 1)
      &.Blue-1
        background-color rgba($color_info_bg, 0.65)
      &.Blue-2
        background-color rgba($color_info_bg, 1)

  .TableBodyWrap
    display flex

  .BodyTitles
    flex-grow 2
    border-right none
    tr
      border-right none
    td
      border-right none
      text-align left
      white-space nowrap
      text-overflow ellipsis
      overflow hidden
      max-width 1px

  .BodyTests
    td
      width 36px
      &.Yellow-1
        background-color rgba($color_yellow_lightest, 0.65)
      &.Yellow-2
        background-color rgba($color_yellow_lightest, 1)
      &.Salmon-1
        background-color rgba($color_salmon_lightest, 0.65)
      &.Salmon-2
        background-color rgba($color_salmon_lightest, 1)
      &.Purple-1
        background-color rgba($color_purple_lightest, 0.65)
      &.Purple-2
        background-color rgba($color_purple_lightest, 1)
      &.Green-1
        background-color rgba($color_primary_lightest, 0.65)
      &.Green-2
        background-color rgba($color_primary_lightest, 1)
      &.Blue-1
        background-color rgba($color_info_bg, 0.65)
      &.Blue-2
        background-color rgba($color_info_bg, 1)

  .BodyTitles,
  .BodyTests
    tr
      &:nth-child(2n-1)
        background-color rgba($color_grey_lighter, 0.4)

  .SampleTitleRow
    td
      width 36px
      max-width 36px
      height 110px
      text-align left
      white-space nowrap
      span
        display block
        &:first-child
          font-size 0.63rem
          transform rotate(-90deg) translateX(-40px) translateY(-7px)
        &:last-child
          font-size 0.5rem
          transform rotate(-90deg) translateX(-27px) translateY(7px)

  table
    border-collapse collapse
    td
      padding 5px
      text-align center

  table, tr, th, td
    border 1px solid $color_grey_light
    font-size 0.875rem
</style>
